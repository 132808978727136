import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "i18n";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  table,
  template,
  textStyle,
} from "suneditor/src/plugins";
import {
  setEditorAllowOpenModels,
  setEditorCustomizeModal,
} from "../../../actions/actions/editor/editor";
import Models from "../../campaign/editor/Models";
import { customLink } from "./plugins/CustomLink";
import FieldsSelector from "./FieldsSelector";
import { EVENT_TYPE, WTB_TYPE } from "../../../constants/dynamicFields";
import { customFreesection } from "./plugins/CustomFreesection";
import { fetchAppDynamicsFields } from "../../../actions/thunks/params";
import { customFillFreesection } from "./plugins/CustomFillFreesection";
// import textExpress from "./plugins/ExpressText";
import { fetchTextModels } from "thunks";
import { initTextModels, setTextModelsTargetApp } from "actions";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/en-gb";
import "moment/locale/nl";

class SunEdit extends Component {
  constructor(props) {
    super(props);
    this.editorRef = null;
    this.setEditorRef = (ref) => {
      this.editorRef = ref;
    };
  }

  componentDidMount() {
    const {
      mode,
      initTextModels,
      setTextModelsTargetApp,
      fetchTextModels,
      onObjectChange,
    } = this.props;
    this.initDynamicFields();
    if (mode === "REDUCED") {
      //initTextModels();
      //setTextModelsTargetApp(["EXPRESS"]);
      //fetchTextModels("fr");
      // textExpress.applyText = ({object, content}) => {
      //   this.onApplyTextModel(content);
      //   onObjectChange(object);
      // };
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { mode, textModels } = this.props;
    if (prevProps.fieldsType !== this.props.fieldsType) {
      this.initDynamicFields();
    }
    if (
      mode === "REDUCED" &&
      textModels?.length &&
      prevProps.textModels !== textModels
    ) {
      // textExpress.setTexts(textModels);
      // const core = this.editorRef.current.editor.core;
      // core.plugins.textExpress.add(core);
    }
  }

  getSunEditorToolbarConfig = (mode = "FULL") => {
    const props = this.props;

    let customModels = {
      name: "customModels",
      display: "command",
      title: _("models"),
      buttonClass: "se-btn-select se-btn-tool-format se-btn-custom",
      innerHTML: `<div class="custom-container"><i class="icon icon-doc"></i> <span>${_(
        "models",
      )}</span></div>`,
      add: function (core, targetElement) {},
      action: function () {
        props.setEditorAllowOpenModels(true);
      },
    };

    const fieldEditor_command = {
      name: "Fields",
      display: "command",
      title: _("Customize"),
      innerHTML: '<i class="icon icon-user"/>',

      add: function (core, targetElement) {},
      action: function () {
        props.setEditorCustomizeModal(true);
      },
    };

    let Params = {
      resizingBar: true,
      showPathLabel: false,
      height: "auto",
      placeholder: _("enterText"),
      stickyToolbar: 0,
      plugins: [
        align,
        font,
        fontColor,
        fontSize,
        formatBlock,
        hiliteColor,
        horizontalRule,
        lineHeight,
        list,
        table,
        template,
        textStyle,
        customLink,
        customModels,
        fieldEditor_command,
        customFreesection,
        // customFillFreesection,
        // textExpress
      ],
      buttonList: [
        ["undo", "redo"],
        ["font"],
        ["fontSize"],
        ["formatBlock"],
        ["bold", "underline", "italic", "strike", "subscript", "superscript"],
        ["fontColor", "hiliteColor"],
        ["removeFormat"],
        ["outdent", "indent"],
        ["align"],
        ["horizontalRule"],
        ["list"],
        ["lineHeight"],
        ["table", "customLink"],
        ["Fields"],
        ["fullScreen"],
        //For Line Break we can use :  "/" + []
      ],
      formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
      font: [
        "Arial",
        "Calibri",
        "Comic Sans",
        "Courier",
        "Garamond",
        "Georgia",
        "Impact",
        "Lucida Console",
        "Palatino Linotype",
        "Segoe UI",
        "Tahoma",
        "Times New Roman",
        "Trebuchet MS",
      ],
      alignItems: ["left", "center", "right", "justify"],
    };

    switch (mode) {
      case "FULL":
        return {
          ...Params,
          // buttonList: [...Params.buttonList].concat([["customModels"], ["customFillFreeSection"]])
          buttonList: [...Params.buttonList].concat([["customModels"]]),
        };
      case "TEXT_MODELS":
        return {
          ...Params,
          buttonList: [...Params.buttonList].concat([["customFreeSection"]]),
        };
      case "REDUCED":
        return {
          ...Params,
          buttonList: [
            ["undo", "redo"],
            ["font"],
            ["fontSize"],
            ["formatBlock"],
            // ["paragraphStyle"],
            [
              "bold",
              "underline",
              "italic",
              "strike",
              "subscript",
              "superscript",
            ],
            ["align", "horizontalRule", "list", "lineHeight"],
            [
              "customLink",
              // "textExpress"
            ],
          ],
          formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
          font: [
            "Arial",
            "Calibri",
            "Comic Sans",
            "Courier",
            "Garamond",
            "Georgia",
            "Impact",
            "Lucida Console",
            "Palatino Linotype",
            "Segoe UI",
            "Tahoma",
            "Times New Roman",
            "Trebuchet MS",
          ],
        };
    }
    return Params;
  };

  initDynamicFields = () => {
    const {
      fetchAppDynamicsFields,
      eventFieldsFetched,
      fieldsType,
      wtbFieldsFetched,
    } = this.props;

    if (![EVENT_TYPE, WTB_TYPE].includes(fieldsType)) {
      return;
    }

    if (
      (fieldsType === WTB_TYPE && !wtbFieldsFetched) ||
      (fieldsType === EVENT_TYPE && !eventFieldsFetched)
    ) {
      fetchAppDynamicsFields(fieldsType);
    }
  };

  addField = (field) => {
    const { onAddField, editorActive } = this.props;
    // Add a null check before accessing the ref
    if (this.editorRef) {
      const editor = this.editorRef;
      if (editorActive) {
        editor.insertHTML(field);
      } else {
        onAddField(field);
      }
      this.toggleFieldsSelector();
    } else {
      console.warn('Editor ref is not available');
    }
  };

  toggleFieldsSelector = () => {
    this.props.setEditorCustomizeModal(false);
  };

  onApplyTextModel = (html) => {
    const editor = this.editorRef;
    // editor.core.focus();
    editor.setContents(html);
    setTimeout(() => {
      const { setEditorAllowOpenModels } = this.props;
      setEditorAllowOpenModels(true);
    }, 0);
  };

  render() {
    const {
      lng,
      changed,
      onFocus,
      content,
      mode,
      onObjectChange,
      onClick,
      modalFieldsOpened,
      fieldsType,
      autoFocus,
    } = this.props;
    moment.locale(lng);

    return (
      <div>
        <SunEditor
          getSunEditorInstance={(sunEditor) => {
            this.setEditorRef(sunEditor);
          }}
          autoFocus={autoFocus !== undefined ? autoFocus : true}
          lang={lng}
          setOptions={this.getSunEditorToolbarConfig(mode)}
          onFocus={onFocus}
          onChange={changed}
          defaultValue={content}
          setContents={content}
          onClick={onClick}
        />
        {modalFieldsOpened && (
          <FieldsSelector
            lng={lng}
            fieldsType={fieldsType}
            onAddField={this.addField}
            close={this.toggleFieldsSelector}
          />
        )}
        {this.props.allowOpenModels && (
          <Models
            onChange={this.onApplyTextModel}
            onEditorChange={changed}
            onObjectChange={onObjectChange}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lng: state.params.lng,
  allowOpenModels: state.editor.allowOpenModels,
  eventFieldsFetched: state.params.dynamicsFields.event.fetched,
  wtbFieldsFetched: state.params.dynamicsFields.wtb.fetched,
  eventFields: state.params.dynamicsFields.event.list,
  wtbFields: state.params.dynamicsFields.wtb.list,
  updating: state.params.dynamicsFields.updating,
  modalFieldsOpened: state.editor.modalFieldsOpened,
  textModel: state.currentCampaign.email.textModel,
  textModels: state.textModels.items,
});

const mapDispatchToProps = (dispatch) => ({
  setEditorCustomizeModal: (modalFieldsOpened) =>
    dispatch(setEditorCustomizeModal(modalFieldsOpened)),
  setEditorAllowOpenModels: (modelsOpened) =>
    dispatch(setEditorAllowOpenModels(modelsOpened)),
  fetchAppDynamicsFields: (appName) =>
    dispatch(fetchAppDynamicsFields(appName)),
  initTextModels: () => dispatch(initTextModels()),
  setTextModelsTargetApp: (targetApp) =>
    dispatch(setTextModelsTargetApp(targetApp)),
  fetchTextModels: (...filter) => dispatch(fetchTextModels(...filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SunEdit);
