import React from 'react';
import {connect} from "react-redux";
import {
  setAutomationPaginationPage,
  setEventsFilters
} from "../../../actions/actions/currentCampaign";
import _ from "i18n";
import {
  fetchAutomationCampaignConfigs,
} from "../../../actions/thunks/campaign";
import uuid from "uuid";
import TTPPaginator from "../../common/list/TTPPaginator";

@connect((store) => {
  return {
    campaignAutomations: store.currentCampaign.email.campaignAutomations,
    automationPageSize: store.currentCampaign.email.automationPageSize,
    automationPaginationPage: store.currentCampaign.email.automationPaginationPage,
    automationNbResult: store.currentCampaign.email.automationNbResult,
    fetching: store.currentCampaign.email.fetching,
  }
}, (dispatch) => {
  return {
    fetchAutomationCampaignConfigs: (pageSize, paginationPage) => dispatch(fetchAutomationCampaignConfigs(pageSize, paginationPage)),
    setEventsFilters: (data) => dispatch(setEventsFilters(data)),
    setAutomationPaginationPage : (pageNumber) => dispatch(setAutomationPaginationPage(pageNumber))
  }
})

export default class EmailAutomationOverview extends React.Component {
  constructor(props) {
    super(props);
    this.handleEdit = this.handleEdit.bind(this);
  }

  componentDidMount() {
    this.props.fetchAutomationCampaignConfigs(this.props.automationPageSize, 1);
  }

  handleEdit(automation) {
    const { includedStatus, excludedStatus } = automation;

    const includedFilters = includedStatus ? JSON.parse(includedStatus) : [];
    const excludedFilters = excludedStatus ? JSON.parse(excludedStatus) : [];

    const combinedFilters = [...includedFilters, ...excludedFilters];
    if (combinedFilters.length > 0){
      combinedFilters.map((filter) => {
        this.props.setEventsFilters(filter);
      })
    }
    this.props.handleHideCreateAutomation(automation);
  }


  handleAutomationListPageClick(data) {
    let selectedPage = data.selected;
    this.props.setAutomationPaginationPage(selectedPage + 1);
    this.props.fetchAutomationCampaignConfigs(this.props.automationPageSize, selectedPage + 1);
  };

  renderTableBody() {
    return (
      <div className="ttp-datatable small-12 columns">
        <div className="row ttp-datatable__header" style={{textTransform: 'unset'}}>
          <div className="small-8">{_("automationCampaignName")}</div>
          <div className="small-4">{_("Actions")}</div>
        </div>
        {this.props.campaignAutomations.length === 0 ?
          <div key={uuid()} className="row ttp-datatable__row">
            <div className="small-12" style={{ padding: '1rem', textAlign: 'center', fontSize: '14px', fontWeight: '500'}}>
              {this.props.fetching ? _('Processing') : _('No data found')}
            </div>
          </div>
        : this.props.campaignAutomations.map((automation, index) => {
          return (
            <div key={uuid()} className="row ttp-datatable__row">
              <div className="small-8">
                {automation.name}
              </div>
              <div className="small-4">
                  <span key={index}
                        style={{marginRight: '0.5rem', cursor: 'pointer'}}
                        onClick={() => this.handleEdit(automation)}>
                        <i className={`icon-left icon-pencil`}/>{_('edit')}
                  </span>
              </div>
            </div>
        );
        })}
        {this.props.automationNbResult > this.props.automationPageSize ?
        <TTPPaginator
          onPageChange={this.handleAutomationListPageClick.bind(this)}
          pageSize={this.props.automationPageSize}
          nbResult={this.props.automationNbResult}
          paginationPage={this.props.automationPaginationPage}
        />
          :
          <></>
        }
      </div>
    );
  }

  render() {
    return (
      <div style={{width: '100%', maxWidth: '64rem', margin: '0 auto'}}>
        <div style={{
          backgroundColor: 'white',
          borderRadius: '0.5rem',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          overflow: 'hidden'
        }}>
          <div style={{
            padding: '1.5rem',
            backgroundColor: '#f8f9fa',
            border: '1px solid #ddd'
          }}>
            <h2 style={{
              fontSize: '1.25rem',
              fontWeight: 500,
              margin: 0,
              color: '#28394C',
            }}>{_('templatesOverview')}</h2>
          </div>
          <div style={{padding: '1.5rem'}}>
              {this.renderTableBody()}
          </div>
          <div style={{
            padding: '1.5rem',
            backgroundColor: '#f8f9fa',
            borderTop: '1px solid #ddd'
          }}>
            <button
              className="button primary"
              style={{
                backgroundColor: '#2196f3',
                padding: '0.5rem 1rem',
                border: 'none',
                borderRadius: '0.25rem',
                color: 'white',
                fontWeight: 'bold',
                cursor: 'pointer'
              }}
              onClick={() => this.props.handleHideCreateAutomation(null)}
            >
              {_('createNewTemplate')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
