import React, {Component} from "react";
import {connect} from "react-redux";
import _ from "i18n";
import "react-datepicker/dist/react-datepicker.css";
import TTPSelect from "common/TTPSelect";
import {
  onError,
  onSuccess,
  SVG_DELETE_ICON,
  ucFirst
} from "../../../services/utils";
import { store } from "../../../index.js";
import { GUEST_STATUS } from 'Common';
import TagsInput from "react-tagsinput";
import {
  setAutomationPaginationPage,
  setEventsFilters
} from "../../../actions/actions/currentCampaign";
import {fetchTemplates} from "../../../actions/thunks/theme";
import {TEMPLATE_TYPES} from "constants";
import {fetchTextModels} from "../../../actions/thunks/textModel";
import TTPLoader from "../../ui/TTPLoader";
import {
  deleteAutomationCampaign,
  fetchAutomationCampaignConfigs,
  saveCampaignAutomation
} from "../../../actions/thunks/campaign";
import Viewer from "../../common/Viewer";
import {TTP_API_URL} from "../../../config";
import {setTTPDialogCustomData} from "../../../actions/actions/params";
import DIALOG_TYPES from "../../../constants/TTPDialog";
import {filter} from "cheerio/lib/api/traversing";

@connect((store) => {
  return {
    events: store.filters.events.items,
    slots: store.filters.slots.items,
    options: store.filters.options.items,
    formulas: store.filters.formulas.items,
    language: store.params.lng,
    eventFilters: store.currentCampaign.email.eventFilters,
    themes: store.themes?.list?.items,
    themesFetching: store.themes.list?.fetching,
    textModels: store.textModels?.items,
    campaignLanguage: store.currentCampaign.email.language,
    token: store.auth.token,
    automationPageSize: store.currentCampaign.email.automationPageSize,
    automationPaginationPage: store.currentCampaign.email.automationPaginationPage,
  }
}, (dispatch, ownProps) => {

  const handleStepsChange = (dispatch, steps, operatorStep, operatorPrinted, operatorConfirmation) => {
    const hasPrinted = steps.includes('105');
    const hasConfirmation = steps.includes('5');
    const otherSteps = steps.filter(step => (step !== '105' && step !== '5'));

    // Get current filters but exclude any registeredChoice filters
    const oppositeOperator = operatorStep === 'in' ? 'nin' : 'in';
    const oppositeFilters = store.getState().currentCampaign.email.eventFilters?.filter(filter =>
      (filter.operator === oppositeOperator && filter.property !== 'registeredChoice') ||
      (filter.operator === (operatorStep === 'in' ? 'neq' : 'eq') && filter.property === 'printed')
    );

    // Clear current step filter
    dispatch(setEventsFilters({
      property: 'step',
      operator: operatorStep,
      value: ''
    }));

    // Clear printed filter if badges imprimés is being removed
    if (!hasPrinted) {
      dispatch(setEventsFilters({
        property: 'printed',
        operator: operatorPrinted,
        value: ''
      }));
    }

    const isNeqRegisteredChoiceStored = store.getState().currentCampaign.email.eventFilters.filter(filter =>
      filter.property === 'registeredChoice' && filter.operator === 'neq' && filter.value === ''
    )
    const isEqRegisteredChoiceStored = store.getState().currentCampaign.email.eventFilters.filter(filter =>
      filter.property === 'registeredChoice' && filter.operator === 'eq' && filter.value === ''
    )

    if (isNeqRegisteredChoiceStored.length === 0 ||
      isNeqRegisteredChoiceStored.length !== 0 && !hasConfirmation && steps.length === 0 && operatorStep === 'in'
      || isNeqRegisteredChoiceStored.length !== 0 && !hasConfirmation && steps.length > 0 && operatorStep === 'in'){
      dispatch(setEventsFilters({
        property: 'registeredChoice',
        operator: 'neq',
        value: null
      }));
    }

    if (isEqRegisteredChoiceStored.length === 0  ||  isEqRegisteredChoiceStored.length !== 0 && !hasConfirmation && steps.length === 0 && operatorStep === 'nin' || isEqRegisteredChoiceStored.length !== 0 && !hasConfirmation && steps.length > 0 && operatorStep === 'nin'){
      dispatch(setEventsFilters({
        property: 'registeredChoice',
        operator: 'eq',
        value: null
      }));
    }

    const newFilters = [];

    // Add regular steps filter
    if (otherSteps.length > 0) {
      newFilters.push({
        property: 'step',
        operator: operatorStep,
        value: otherSteps
      });
    }

    // Add printed filter only if badges imprimés is selected
    if (hasPrinted) {
      newFilters.push({
        property: 'printed',
        operator: operatorPrinted,
        value: 1
      });
    }

    // Add confirmation filter only if confirmation is explicitly selected
    if (hasConfirmation) {
      newFilters.push({
        property: 'registeredChoice',
        operator: operatorStep === 'in' ? 'neq' : 'eq',
        value: ''
      });
    }

    // Apply new filters while preserving opposite operator filters
    [...newFilters, ...oppositeFilters].forEach(filter => {
      if (filter.value !== null) {
        dispatch(setEventsFilters(filter));
      }
    });

  };

  return {
    handleEventChange: (eventId) => dispatch(setEventsFilters({ property: 'event.id', operator: 'eq', value: eventId })),
    handleStepsInChange: (steps, eventFilters) => handleStepsChange(dispatch, steps, 'in', 'eq', 'neq'),
    handleStepsNinChange: (steps, eventFilters) => handleStepsChange(dispatch, steps, 'nin', 'neq', 'eq'),
    fetchThemes: (filter) => dispatch(fetchTemplates(filter)),
    fetchTextModels: (lng) => dispatch(fetchTextModels(lng)),
    saveCampaignAutomation: (data) => dispatch(saveCampaignAutomation(data)),
    deleteCampaignAutomation: (campaignAutomationId) => dispatch(deleteAutomationCampaign(campaignAutomationId)),
    setEventsFilters: (data) => dispatch(setEventsFilters(data)),
    fetchAutomationCampaignConfigs: (pageSize, paginationPage) => dispatch(fetchAutomationCampaignConfigs(pageSize, paginationPage)),
    setTTPDialogCustomData: (data) => dispatch(setTTPDialogCustomData(data)),
    setAutomationPaginationPage : (pageNumber) => dispatch(setAutomationPaginationPage(pageNumber))
  }
})
export default class EmailAutomationCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSimple: false,
      object: '',
      name: '',
      lang: '',
      textModel: '',
      theme: '',
      hideRegisterButton: true,
      automation: null,
      showLoader: true,
      previewIsOpen: false,
      isThemeSet: false,
      selectedTheme: '',
    };
    this.handleSelectedThemes = this.handleSelectedThemes.bind(this);
    this.handleSelectedThemesType = this.handleSelectedThemesType.bind(this);
    this.handleObjectChangeInput = this.handleObjectChangeInput.bind(this);
    this.handleNameChangeInput = this.handleNameChangeInput.bind(this);
    this.handleTextModelChange = this.handleTextModelChange.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.saveAutomation = this.saveAutomation.bind(this);
    this.removeAutomation = this.removeAutomation.bind(this);
  }

  componentDidMount() {
    let filter = {};
    let isSimple = this.props.automation ? this.props.automation.textModel : this.state.isSimple;
    filter.type =  isSimple ? [TEMPLATE_TYPES['SIMPLE'], TEMPLATE_TYPES['WRAPPER']] : TEMPLATE_TYPES['DRAG_AND_DROP'];
    filter.forAutomation = true;
    filter.allThemes = true;
    this.props.fetchThemes(filter);
  }

  componentDidUpdate(prevProps, prevState) {
    const { eventFilters, automation, themes } = this.props;
    const {
      object,
      name,
      lang,
      textModel,
      theme,
      isSimple,
      selectedTheme
    } = this.state;
    const includedStatus = eventFilters?.filter(condition => condition.operator === 'in' || condition.operator === 'neq' && condition.value !== null);
    const excludedStatus = eventFilters?.filter(condition => condition.operator === 'nin' || condition.operator === 'eq' && condition.value !== null);

    let shouldHideRegisterButton = true;

    // Check if all required fields are filled
    const hasRequiredFields = name !== '' && lang !== '' && theme !== '';
    const hasValidObject = selectedTheme?.themeObject || object !== '';
    const hasValidTextModel = !isSimple || (isSimple && textModel !== '');
    const hasValidFilters = includedStatus.length > 0 || excludedStatus.length > 0;

    // Show register button if all conditions are met
    if (hasRequiredFields && hasValidObject && hasValidTextModel && hasValidFilters) {
      shouldHideRegisterButton = false;
    } else {
      shouldHideRegisterButton = true;
    }




    if (this.state.hideRegisterButton !== shouldHideRegisterButton) {
      this.setState({ ...this.state, hideRegisterButton: shouldHideRegisterButton });
    }
    if(prevState.isSimple !== isSimple) {
      let filter = {};
      filter.type = isSimple ? [TEMPLATE_TYPES['SIMPLE'], TEMPLATE_TYPES['WRAPPER']] : TEMPLATE_TYPES['DRAG_AND_DROP'];
      filter.allThemes = true;
      filter.forAutomation = true;
      this.setState({showLoader: false})
      this.props.fetchThemes(filter);
      if (isSimple){
        this.props.fetchTextModels();
      }
    }

    if (prevState.automation !== automation){
      this.setState({...this.state, automation: automation, theme: automation.theme ? automation.theme?.id : this.state.theme,
        name: automation.name, object: automation.campaignObject, lang: automation.language, textModel: automation.textModel ? automation.textModel.id : this.state.textModel,
        isSimple: automation.textModel ? true : this.state.isSimple});
    }

  }


  handleSelectedThemesType() {
    this.setState({showLoader: false, isThemeSet: false})
    this.setState({...this.state, isSimple: !this.state.isSimple, theme: '', textModel: ''});
  }

  handleSelectedThemes(theme) {
    const { selectedTheme, object } = this.state;
    const updatedTheme = this.props.themes?.filter(item => theme == item.id);
    this.setState({...this.state, theme: theme, isThemeSet: true, object: updatedTheme && updatedTheme[0].themeObject ? updatedTheme[0].themeObject : '',
      selectedTheme: updatedTheme ? updatedTheme[0] : selectedTheme});
  }

  handleObjectChangeInput(object) {
    this.setState({...this.state, object: object});
  }

  handleNameChangeInput(name) {
    this.setState({...this.state, name: name});
  }

  handleTextModelChange(textModel) {
    this.setState({...this.state, textModel: textModel});
  }

  handleLanguageChange(language) {
    this.setState({...this.state, lang: language});
  }

  handleCancel() {
    this.setState( {
        isSimple: false,
        object: '',
        name: '',
        lang: '',
        textModel: '',
        theme: '',
        hideRegisterButton: true,
        automation: null,
        showLoader: true,
        previewIsOpen: false
      }
    );
    this.props.handleHideCreateAutomation(null);
  };

  saveAutomation = () => {
    const { theme, textModel, name, object, lang, automation } = this.state;
    const data = { theme, textModel, name, campaignObject: object, language: lang, id: automation ? automation.id : null };
    this.props.saveCampaignAutomation(data).then(res => {
      onSuccess(res);
      this.props.setAutomationPaginationPage(1);
      this.props.fetchAutomationCampaignConfigs(this.props.automationPageSize, 1);
      this.props.handleHideCreateAutomation(null);
      this.setState( {
          isSimple: false,
          object: '',
          name: '',
          lang: '',
          textModel: '',
          theme: '',
          hideRegisterButton: true,
          automation: null,
          showLoader: true,
        }
      );
    }, err => onError(err));
  }

  removeAutomation = () => {
    const { automation } = this.state;
    this.props.deleteCampaignAutomation(automation.id).then(res => {
      onSuccess(res);
      this.props.setAutomationPaginationPage(1);
      this.props.fetchAutomationCampaignConfigs(this.props.automationPageSize, 1);
      this.props.handleHideCreateAutomation(null);
      this.setState( {
          isSimple: false,
          object: '',
          name: '',
          lang: '',
          textModel: '',
          theme: '',
          hideRegisterButton: true,
          automation: null,
          showLoader: true,
        }
      );
    }, err => onError(err));
  }

  deleteAutomation = () => {
    this.props.setTTPDialogCustomData({
      approvedAction: () => this.removeAutomation(),
      message: _("deleteCampaignAutomationConfirm"),
      proceedBtnLabel: _("yesDelete"),
      abortBtnLabel: _("no"),
      title: "Confirmation of deletion",
      type: DIALOG_TYPES["ALERT"],
      svgIcon: SVG_DELETE_ICON,
    });
  }

  render() {
    const { eventFilters, handleStepsNinChange, handleStepsInChange, themes, textModels, automation, themesFetching } = this.props;
    const { object, isSimple, name, lang, textModel, theme, hideRegisterButton, showLoader, previewIsOpen, isThemeSet, selectedTheme } = this.state;
    if (themesFetching && showLoader){
      return (<div style={{position: "relative", paddingBottom: '20rem'}}>
        <TTPLoader />
      </div>);
    }
    let filterMapping = eventFilters.reduce((filters, filter) => {
      let key = `${filter.property}-${filter.operator}`;
      filters[key] = filter.value;
      return filters;
    }, {});
    const shouldOpenPreview = !isSimple && theme || isSimple && theme && textModel;

    let includedSteps = filterMapping['step-in'] || [];
    let excludedSteps = filterMapping['step-nin'] || [];
    let isIncludedPrinted = filterMapping['printed-eq'] === 1;
    let isExcludedPrinted = filterMapping['printed-neq'] === 1;
    let isIncludedConfirmation = filterMapping['registeredChoice-neq'] === '';
    let isExcludedConfirmation = filterMapping['registeredChoice-eq'] === '';
    if (isIncludedPrinted && !includedSteps.includes('105')) {
      includedSteps = [...includedSteps, '105'];
    }

    if (isExcludedPrinted && !excludedSteps.includes('105')) {
      excludedSteps = [...excludedSteps, '105'];
    }

    if (isIncludedConfirmation && !includedSteps.includes('5')) {
      includedSteps = [...includedSteps, '5'];
    }

    if (isExcludedConfirmation && !excludedSteps.includes('5')) {
      excludedSteps = [...excludedSteps, '5'];
    }

    let themeOptions = themes?.map(theme => {
      return {
        label: theme.name,
        id: theme.id
      };
    });

    let texteModelsOptions = textModels?.map(textModel => {
      return {
        label: textModel.object,
        id: textModel.id
      };
    });

    const languagesOptions = [{id: 'fr', label: _('fr')},{id: 'nl', label: _('nl')}, {id: 'all', label: _('all')}];

    return (
      <>
        {previewIsOpen &&
          <Viewer
            isOpen={previewIsOpen}
            onClose={() => this.setState({previewIsOpen: false})}
            url={`${TTP_API_URL}/mailing/web-version?themeId=${theme}&textModelId=${textModel}&access_token=${this.props.token}`}
          />
        }
        <div className="row">
          <div className="columns small-6 columns-padding">
            <div>
              <h1 className="param__title" style={{
                fontWeight: '600',
                fontSize: '22px',
                marginTop: '56px',
                marginLeft: '-15px'
              }}>{!automation ? _('eventCampaignTemplateCreate') : _('eventCampaignTemplateEdit')}</h1>
            </div>
          </div>
          <div className="columns small-6 columns-padding">
          </div>
        </div>
        <div className="row">
          <div className="columns small-6 columns-padding">
            <div className="sidebar__form-control">
              <h1 className="param__title">{_('automationCampaignName')}</h1>
              <TagsInput
                value={[]}
                onlyUnique={true}
                addOnPaste
                inputProps={{placeholder: _("automationCampaignName")}}
                inputValue={name}
                onChangeInput={this.handleNameChangeInput}
              />
            </div>
          </div>
          <div className="columns small-6 columns-padding">
            {(!(selectedTheme?.themeObject) && isThemeSet || automation && automation.campaignObject && !automation.theme?.themeObject) &&
              <div className="sidebar__form-control">
                <h1 className="param__title"
                    style={{margin: '1rem 0 0.34rem'}}>{_('campaignSubject')}</h1>
                <TagsInput
                  value={[]}
                  onlyUnique={true}
                  addOnPaste
                  inputProps={{placeholder: _("campaignSubject")}}
                  inputValue={object}
                  onChangeInput={this.handleObjectChangeInput}
                />
                <span
                  className={!object ? 'form-error is-visible' : 'form-error'} style={{marginLeft: '10px'}}>
                  {_("errorTheme")}
                </span>
              </div>
            }
          </div>
          <div className="columns small-5 columns-padding">
            <div className="form-control">
              <h1 className="param__title"
                  style={{margin: '1rem 0 0.34rem'}}>{!isSimple ? _('theme') : _('model')}
                <span style={{float: "right", display: "flex"}}>
                  <input
                    type="checkbox"
                    style={{
                      display: 'flex',
                      height: '16px',
                      width: '17px',
                      zIndex: '3'
                    }}
                    id={'1'}
                    checked={isSimple}
                    onChange={this.handleSelectedThemesType}
                  />
                    <span
                      style={{margin: '-1px 0 0 -10px'}}>{_('simple')}</span>
                </span>
              </h1>
              <TTPSelect
                simple={true}
                notClearable={true}
                values={theme}
                placeholder={_('selectTheme')}
                options={themeOptions}
                onChange={this.handleSelectedThemes}
              />
            </div>
          </div>
          {isSimple &&
            <div className="columns small-5 columns-padding">
              <div className="form-control">
                <h1 className="param__title"
                    style={{margin: '1rem 0 0.34rem'}}>{_('contentForTheme')}</h1>
                <TTPSelect
                  simple={true}
                  notClearable={true}
                  values={textModel}
                  placeholder={_('select Content')}
                  options={texteModelsOptions}
                  onChange={this.handleTextModelChange}
                />
              </div>
            </div>
          }
          {shouldOpenPreview &&
            <div className="columns small-2 columns-padding"
                 style={{paddingTop: '3rem'}}>
              <button
                className="btn secondary side-page-header__button"
                onClick={() => {
                  this.setState({previewIsOpen: true})
                }}
              >
                <i className={`icon-left icon-eye`}/>
                <span>{_('preview')}</span>
              </button>
            </div>
          }
          {!isSimple &&
            <div className="columns small-5 columns-padding"/>
          }
          <div className="recipients-selection columns small-12 columns-padding">
            <h2
              className="recipients-selection__title">{_('recipientsSelection')}</h2>
            <div className="recipients-selection__content">
              <div className="columns small-6 columns-padding">
                <div className="form-control">
                  <h1 className="param__title"
                      style={{margin: '1rem 0 0.34rem'}}>{_('Included status')}</h1>
                  <TTPSelect
                    values={includedSteps}
                    placeholder={_('Select status')}
                    options={GUEST_STATUS}
                    onChange={handleStepsInChange}
                    disabled={excludedSteps}/>
                </div>
              </div>
              <div className="columns small-6 columns-padding">
                <div className="form-control">
                  <h1 className="param__title"
                      style={{margin: '1rem 0 0.34rem'}}>{_('Excluded status')}</h1>
                  <TTPSelect
                    values={excludedSteps}
                    placeholder={_('Exclude status')}
                    options={GUEST_STATUS}
                    onChange={handleStepsNinChange}
                    disabled={includedSteps}/>
                </div>
              </div>
              <div className="columns small-6 columns-padding">
                <div className="form-control">
                  <h1 className="param__title"
                      style={{margin: '1rem 0 0.34rem'}}>{_('language')}</h1>
                  <TTPSelect
                    simple={true}
                    notClearable={true}
                    values={lang}
                    placeholder={_('select language')}
                    options={languagesOptions}
                    onChange={this.handleLanguageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ttp-small-buttons"
             style={{margin: '1.5rem 0 5rem 0'}}>
          <button className="cancel"
                  onClick={this.handleCancel}>{_('cancel')}</button>
          <button className={`success`}
                  style={hideRegisterButton ? {
                    background: '#b7e6dd',
                    pointer: 'unset'
                  } : {}}
                  onClick={!hideRegisterButton ? this.saveAutomation : () => {
                  }}>{_('save')}</button>
          {automation &&
            <div style={{position: 'absolute', right: '24vh'}}>
            <span style={{cursor: 'pointer', color: 'red', lineHeight: 3}}
                  onClick={() => this.deleteAutomation()}>
                    <i className={`icon-left danger icon-trash`}/>{_('deleteTemplate')}
            </span>
            </div>
          }
        </div>
      </>
    );
  }
}



