export default {
  home: "Emailing",
  emailing_banner:
    "Send emailings to the look & feel of your community with little effort!",
  signIn: "Login / Sign up",
  "About us": "",
  owrApps: "Owr Apps",
  Dashboard: "Dashboard",
  pageNotFound: "This Page Could Not Be Found!",
  goBackToHome: "Go back to the Home page",
  logout: "",
  help: "",
  Communities: "",
  campaigns: "Campaigns",
  webPage: "Web page",
  previous: "Previous",
  next: "Next",
  filter: "Filter",
  filters: "Filters",
  "roles status": "",
  status: "Status",
  DRAFT: "",
  SENT: "",
  USER: "",
  ADMIN: "",
  NONE: "",
  RECIPIENT_LIST_CREATED: "In progress",
  language: "Language",
  reset: "Reset",
  searchBySubject: "Search by subject",
  subject: "Subject",
  cancel: "",
  ignore: "ignore",
  save: "",
  codeOwn: "Write yours",
  createdAt: "Created At",
  groups: "Groups",
  sent: "Sending",
  enterText: "Message...",
  enterName: "Enter a name",
  deleteCampaignConfirm: "Are you sure you want to delete the campaign?",
  delete: "Delete",
  fromEmail: "from email",
  fromName: "from name",
  replyTo: "reply to",
  recipientType: "RECIPIENT TYPE",
  test: "",
  parameters: "",
  typeTestEmail: "Type the emails address you want to send the test to",
  warning: "Warning",
  groupsIncluded: "Groups included",
  excludedGroups: "Excluded groups",
  live: "Live",
  replay: "Replay",
  this_week: "This Week",
  future: "Future",
  past: "Past",
  model_object: "Model object : ",
  dynamic_fields: "Dynamic fields",
  sender_name: "SENDER NAME",
  guest_barcode: "GUEST BARCODE",
  event_id: "EVENT ID",
  event_banner: "EVENT BANNER",
  event_name: "EVENT NAME",
  event_date: "EVENT DATE",
  event_start_time: "EVENT START DATE",
  event_end_time: "EVENT END DATE",
  event_place: "EVENT END DATE",
  event_user_documents_url: "USER DOCUMENTS PAGE",
  event_offfcourse_home_url: "OFFFCOURSE HOME PAGE",
  event_contact_email: "EVENT CONTACT EMAIL",
  event_end_if_has_documents: "END IF HAS DOCUMENTS",
  event_program_url: "EVENT PROGRAM PAGE",
  event_inscription_url: "EVENT INSCRIPTION PAGE",
  event_payment_url: "EVENT PAYMENT PAGE",
  event_documents_url: "EVENT DOCUMENTS PAGE",
  event_start_if_has_documents: "START IF HAS DOCUMENTS",
  event_evaluation_url: "EVENT EVALUATION PAGE",
  event_certificate_url: "EVENEMENT CERTIFICAAT PAGINA",
  organizer_iban: "ORGANIZER IBAN",
  organizer_bic: "ORGANIZER BIC",
  organizer_name: "ORGANIZER NAME",
  recipient_first_name: "RECIPIENT FIRST NAME",
  recipient_last_name: "RECIPIENT LAST NAME",
  recipient_email: "RECIPIENT E-MAIL",
  recipient_guest_barcode: "GUEST BARCODE",
  recipient_user_barcode: "USER BARCODE",
  recipient_plan: "RECIPIENT PLAN",
  recipient_program: "RECIPIENT PROGRAM",
  certificate_comment: "CERTIFICATE COMMENT",
  certificate_hours: "CERTIFICATE HOURS",
  partner_guest_coupon: "PARTNER GUEST COUPON",
  partner_name: "PARTNER NAME",
  survey_title: "SURVEY TITLE",
  survey_url: "SURVET URL",
  attachedCampaigns: "The following campaigns are attached to the template :",
  delete_all: "Delete all",
  deleteModel: "Delete model",
  Training_type: "Training Type",
  selectGroups: "Select Groups",
  showOnlyDefaultModels: "Show only the default models",
  "Select event": "",
  "Select invitation type": "",
  "Select visitor type": "",
  "Select activities": "",
  "Exclude activities": "",
  "Select status": "",
  "Exclude status": "",
  excludeGroups: "Exclude Groups",
  sendTitle: "You're about to send a campaign to",
  tested: "",
  attachments: "Attachments",
  confirm: "",
  confirmSendCampaign: "Are you sure you want to send this campaign?",
  noNotNow: "NO, NOT NOW",
  yesSend: "YES, SEND",
  sendTestTo: "Send SMS test to",
  sendEmailTestTo: "Send email test to",
  sendTest: "SEND TEST",
  resendTest: "RESEND TEST",
  sendCompanion: "Send Companion",
  send: "",
  PLAIN_TEXT: "PLAIN TEXT",
  WEB_PAGE: "WEB PAGE",
  DND_TEMPLATE: "TEMPLATE",
  selectTarget: "Select targeted audiences",
  campaignInfo: "Campaign info",
  invalidObject:
    "object must be at least 5 characters and no more than 200 characters",
  invalidFromName: "from name invalid, Please enter more than 4 character",
  invalidEmail: "invalid email address",
  emailAdd: "Add emails and type ENTER",
  userAdd: "Add user and type ENTER",
  deleteCampaignSuccess: "Successful deletion",
  campaignDeleted: "The campaign was successfully deleted",
  campaignTestSent: "A test email has been successfully sent",
  campaignTestSuccess: "Successful test",
  campaignSent: "The campaign was sent successfully",
  campaignSentSuccess: "Successful sending",
  campaignAdded: "The campaign was saved successfully",
  addCampaignSuccess: "Successful adding",
  successfulAction: "Successful action",
  operationCompleted: "action performed successfully",
  errorOccured: "An error occured, Please retry later.",
  incompleteOperation:
    "Unable to complete operation, incorrect data was submited",
  invalidUrl: "Unable to complete operation, invalid url was submited",
  error: "",
  enterUrl: "Enter URL",
  search: "Search",
  deliveredTo: "Delivered to",
  emailsOpen: "Emails open",
  linkClicks: "Link clicks",
  Bounce: "",
  bounce_info:
    "BOUNCE describes an undelivered email due to: a persistent problem (ex: domain name no longer exists or bad syntax of email), or a temporary problem (ex: the recipient's server is unavailable or its inbox is full",
  permanent_bounce: "new hard bounce",
  old_permanent_bounce: "old hard bounce",
  transient_bounce: "temporary bounce",
  undetermined_bounce: "undetermined bounce",
  recipientsMessage: "this filter takes into consideration the language :",
  pauseCampaign:
    "pausing the campaign will prevent any furthers <br> emails from getting sent until it is reactivated",
  pauseSending: "pause sending any furthers emails",
  stopCampaign: "stop definitely",
  register_from_email:
    "This address is not valid for sending via Amazon, Click on SUBMIT to start the registration process.",
  reply_aws_email:
    "An email was sent to you by Amazon, Reply and click on REFRESH",
  replyToError:
    "Please enter a valid email or text in the format : name <email@example.com>",
  GUEST: "",
  SPEAKER: "",
  CONTACT: "",
  PROSPECT: "",
  PARTNER: "",
  MAILING_LIST: "mailing list",
  SURVEY_TARGETS: "survey targets",
  validate: "Validate",
  refresh: "",
  templateChoices: "Please select a template",
  stopCampaignConfirm: "Do you really want to stop this campaign definitely?",
  stop: "STOP",
  "first name": "",
  "last name": "",
  Event: "",
  "Included activities": "",
  "Excluded activities": "",
  "Included status": "",
  "Excluded status": "",
  Formulas: "",
  Slots: "",
  Options: "",
  templates: "",
  NEXT: "Next",
  emailCampaigns: "Email campaigns",
  smsCampaigns: "SMS campaigns",
  fromPhoneNumber: "FROM PHONE NUMBER ",
  smsMessage: "SMS Message ",
  searchByCampaignName: "Search by campaign name",
  testRecipientsNameAdd: "Add recipient names and tap ENTER",
  typeTestRecipients: "Insert the recipients you want to send the test to",
  campaignSMSTestSent: "A test SMS has been successfully sent",
  nothing_found: "Oops, No results found for your search",
  empty_sms_campaigns_list: "Your organization has no SMS campaigns yet",
  empty_sms_bills_list: "Your organization has no SMS bills yet",
  empty_sms_credit_purchase_list:
    "No SMS credit purchase requests at this moment",
  empty_sms_recipients_list: "No recipients to display yet",
  yes: "",
  no: "",
  french: "",
  english: "",
  dutch: "",
  overview: "",
  links: "",
  recipients: "",
  approval_preferences: "Approval preferences for an emailing campaign",
  "Send your campaign immediately": "",
  "Schedule sending of your campaign for a specific time": "",
  "send to approval teams": "",
  "the approvers": "",
  "approval is required": "",
  "the approval threshold": "",
  "automatic sending": "",
  automatic_sending_paragraph:
    "send the campaign automatically when the approval threshold is reached without rejection",
  approval_obligatory_paragraph:
    "the email campaign can not be sent without approval",
  minimum_acceptance_paragraph:
    "the minimum number of approvals required to approve the campaign",
  no_member_found:
    "Add members to your team, members will review your campaign before sending",
  "Add a member": "",
  "delivery date": "date and time of sending",
  "Drag and drop some files here, or click to select files to upload.": "",
  Attachments: "",
  about_us_content: `is a suite of tools that enable a community to communicate, share, organise or collaborate efficiently. The platform is equipped with various applications assembled as required by the customer. Thanks to our mutuality tools, our customers have the latest technologies without having to develop them and invest in their core business.`,
  createEmail: "create an email campaign",
  createSms: "create an SMS campaign",
  createCampaign: "Create campaign",
  smsSendTitle: "You're about to send a campaign SMS to",
  campaignName: "Campaign name",
  smsOpen: "SMS open",
  unsent: "Unsent",
  originator: "Originator",
  remainingChars: "Remaining characters",
  chars: "Characters",
  pauseSendingSMS: "pause sending any furthers SMS messages",
  pauseSMSCampaign:
    "pausing the campaign will prevent any furthers <br> SMS messages from getting sent until it is reactivated",
  smsTotalCount: "total number of SMS",
  poorCredit: "  your credit is insufficient to send the campaign!",
  richCredit: "  Your credit is sufficient, you still have:",
  "You can send the campaign.": "",
  " You only have: ": "",
  "Sent to": "",
  "Delivered to": "",
  preview: "",
  "INSERT FIELDS": "",
  LEGAL_REPRESENTATIVE: "Legal Representative",
  OFFICIAL: "Official",
  EXTERNAL: "External",
  MANAGER: "Manager",
  "resend the email to": "stuur de e-mail opnieuw naar",
  "In progress": "",
  Buffered: "",
  "sms finances": "",
  "SMS Bills": "",
  "Buy credit": "",
  " Request": "",
  buyCreditDesc:
    "Please enter  the amount of credits to reload your SMS balance in the next frame.",
  buyCreditInfo: "Your credit allow you to send messages to any country.",
  requestSent: "Your request has been successfully sent ",
  sendRequestSuccess: "Successful request sending",
  PENDING: "",
  VALIDATED: "",
  REJECTED: "",
  "Phone numbers with check mark": "Phone numbers with a check mark",
  "have been verified by our system": "have been verified by our system",
  buffered:
    "Message is on the network and awaiting delivery to the handset.<br>Buffered will usually appear when there is either network or handset congestion",
  Credit: "",
  Cost: "",
  detailed_bill: "SMS campaign detailed bill",
  campaign_name: "Campaign name",
  remove_from_list:
    "Are you sure you want to remove the contact from this list?",
  "exclude event registrants": "",
  subscribe: "",
  options: "",
  "embedded form": "",
  "copy/paste onto your site": "",
  copy: "",
  "copied !": "",
  "TOTAL APPROXIMATE COST": "",
  "invalid price": "",
  total_delivered_recipients: "Total recipients",
  total_delivered_sms: "Total SMS messages",
  amount_due: "Amount due",
  country: "Country",
  unit_price: "Unit price",
  delivered_by_country: "Delivered Recipients",
  sms_delivered_by_country: "Delivered SMS",
  total_amount_by_country: "Amount",
  sms_length_warning:
    "You have used special characters (unicode) that can not be displayed in a standard SMS message. Choose an option below:<br>Send an SMS containing only standard characters. The special characters will be replaced by their standard equivalents, or if necessary, deleted.<br> Show special characters in my SMS message. Warning ! In this case, the SMS can only contain 70 characters. The character counter adapts accordingly.<br>",
  approximateCostInfo:
    "The total cost is approximate following the insertion of dynamic fields",
  "TOTAL COST": "",
  sms_warning_lead: "Special characters",
  sms_warning_length_disclaimer:
    "Attention‼ the length of your SMS depends on the characters you use.",
  sms_warning_explanation:
    "Currently, you are using special characters that can not be displayed in a standard SMS message without affecting its size.",
  sms_waring_more_precisely: "More precisely",
  sms_warning_gsm_7bit_guideline:
    "these characters are counted for two characters in an SMS, instead of one :",
  sms_warning_utf16_guideline:
    "Special Characters (such as á é í ó ú à è ë ï ö ü ĳ …) reduce the capacity of the SMS to only 70 characters instead of 160.",
  sms_warning_advice:
    "In order to avoid the disadvantages of this particular use, we invite you to use only standard characters.",
  sms_warning_gsm_7bit_ext_list: "⏎  \\  ^  ~  [  ] {  } |  €",
  sms_warning_counter_disclaimer:
    "The characters counter will adapt accordingly.",
  validationCreditRequestConfirm:
    "Are you sure you want to validate the credit request?",
  creditRequestValidated: "The credit request was successfully validated",
  validateCreditSuccess: "Successful validation",
  date: "",
  "client name": "",
  Reject: "",
  rejectionCreditRequestConfirm:
    "Are you sure you want to reject the credit request?",
  creditRequestRejected: "The credit request was successfully rejected",
  rejectCreditSuccess: "Successful rejection",
  updatedAt: "Updated At",
  authorized_balance: "Allowed balance",
  current_balance: "Current balance",
  "include form title": "",
  "show only required fields": "",
  "show required field indicators": "",
  "show language choices": "",
  required: "",
  resend: "",
  "Nothing to show": "",
  back: "Go back",
  campaign_created_at: "Created",
  at: "at",
  containDynamicFields: "Contains dynamic fields",
  CREDIT: "",
  DEBIT: "",
  "sms credit validation": "",
  "send me a test email": "",
  "show mailing lists choice": "",
  "Please choose the lists to which you want to subscribe": "",
  "Emailing Chart": "",
  "filter by language": "",
  "filter by status": "",
  "Nothing Found": "",
  Balance: "Balance",
  no_approval_sent: "No approval request sent for this version",
  "Sorry, but nothing matched your search terms.": "",
  name: "Name",
  Actions: "",
  "Sent at": "",
  Language: "Lang.",
  "Phone number": "",
  Targets: "Targets",
  stats: "Statistics",
  "not available": "not available",
  "IN PROGRESS": "In progress",
  SUCCESS: "Success",
  READY: "Waiting",
  FAILED: "Failed",
  BUFFERED: "Buffered",
  approval: "Approval",
  "SMS approximate cost": "",
  "SMS cost": "",
  edit: "Edit",
  resume: "Resume",
  show: "Show",
  block: "Block",
  manage: "Manage",
  "sms bill detail": "SMS Bill detail",
  invalid_object_warning:
    "Add an <u>object</u> that clearly describes the content of your email.",
  content_warning: "Add a <u>content</u>",
  from_name_warning:
    "Choose a <u>name</u>, such as your company name, which helps your subscribers recognize you.",
  invalid_from_email_warning:
    "Choose a <u>valid email</u> that your recipients see when they receive your campaign.",
  recipient_count_warning:
    "You need at least 1 <u>recipient</u> To send a campaign",
  approval_required_warning:
    "Your campaign requires <u>approval</u>, it will not be possible to send it until your <u>approvers</u> have accepted it",
  scheduled_approval_required_warning:
    "your campaign can be scheduled, but it will only be sent on the date entered if your <u>approvers</u> have accepted it",

  cant_submit_approval:
    "This campaign is already sent or deleted, you can not submit your approval",
  smsManualTargets:
    "Enter your target's mobile phone numbers in international format",
  invalidPhoneNumber: "invalid phone number or invalid format",
  FIDUCIARIES_CLIENT: "CLIENTS OF FIDUCIARY",
  empty_e_box: "Your E-box is empty at the moment",
  sender: "",
  from: "",
  searchByMail: "Search by e-mail",
  OPT_IN_SOFT: "OPT IN SOFT",
  OPT_IN: "OPT IN",
  OPT_OLD: "OPT OLD",
  "Find other Ebox": "",
  "Search for recipient": "",
  ebox_empty: "Oops, E-box empty for the moment",
  confirmResendEmail: "Are you sure you want to resend this email?",
  to: "",
  toMyEBox: "return to my ebox",
  of: "",
  showing: "",
  emails: "",
  no_attachments: "No attachments to view at the moment!",
  Gallery: "",
  "List of attachments received": "",
  "sms models": "",
  apply: "",
  smsModelsPreviewMessage:
    "Manual mode: Dynamic fields are not taken into consideration.",
  unsubscribe: "Unsubscribe",
  web_version: "Web version",
  social_orientation_row: "Row",
  social_orientation_column: "Column",
  social_theme_type_logo: "Logo",
  social_theme_type_square: "Square",
  social_theme_type_rounded: "Rounded",
  social_theme_type_circle: "Circle",
  social_theme_color_colored: "Colored",
  social_theme_color_colored_bordered: "Colored bordered",
  social_theme_color_black: "Black",
  social_theme_color_black_bordered: "Black bordered",
  social_theme_color_white: "White",
  social_theme_color_white_bordered: "White bordered",
  "Search by name": "",
  "Are you sure you want to delete this model": "",
  empty_recipients_list: "the list of recipients is empty",
  scheduledFor: "Scheduled for",
  share_template_info:
    "Shared models are visible and can be used by the entire communities, while private templates are only accessible by the owner community.",
  no_images: "No images to view at the moment!",
  "Images used": "",
  "Images available": "",
  "Target your audience with precision": "",
  "e-Box your consolidated inbox!": "",
  survey_invitation_detail:
    "your members who will be later the base  of your revival campaigns",
  survey_revival_detail:
    "recipients are those who received your invitation campaigns but did not respond to the survey",
  survey_partial_revival_detail:
    "recipients who have not yet completed the survey",
  survey_participations_detail: "recipients who participated in the survey",
  survey_results_detail: "recipients subscribed to the survey result",
  textModelAdded: "A new text model has been successfully added",
  textModelUsed: "this text model is used in a combination",
  deleteTextModelConfirm: "Are you sure you want to delete this text model?",
  PARTNER_GUEST: "partner guest",
  "visitor type": "",
  updateSuccess: "Successful edit",
  duplicateSuccess: "Successful duplication",
  textModelDuplicated: "The text model has been successfully duplicated",
  textModelUpdated: "The text model has been successfully modified",
  invalidTitle:
    "Title must be at least 3 characters and no more than 200 characters.",
  bill: "Factuur",
  campaign_duplicated_successfully:
    "the campaign has been successfully duplicated",
  invalid_campaign_name_warning:
    "Add a <u>campaign name</u> that clearly describes the content of your email.",
  sms_message_warning: "Add an <u>SMS message</u>",
  not_enough_credit_warning:
    "It will not be possible to send your SMS campaign until you buy some credit.",
  "Phone numbers with check marks<br>have been verified by our system": "",
  notificationPushTitle: "Send the notification to:",
  invalid_object_notification_warning:
    "Add an <u>object</u> that clearly describes the content of your notification.",
  recipient_count_notification_warning:
    "You need at least 1 <u>recipient</u> To send a notification",
  deleteNotificationConfirm:
    "Are you sure you want to delete the notification?",
  notificationDeleted: "The notification was successfully deleted",
  notification_duplicated_successfully:
    "the notification has been successfully duplicated",
  duplicateNotificationConfirm:
    "Are you sure you want to duplicate the notification?",
  params_lang_info:
    "the language is counted <br/> in the selection of recipients",
  QUALITY_MANAGER: "QUALITY MANAGER",
  saveTextModelConfirmation:
    "The target has been changed! Are you sure you want to save the text model?",
  UnavailableLanguagesNote: "Note: Please fill in the unavailable languages",
  "show all": "Show All",
  "hide all": "hide all",
  "verified senders": "",
  mainTextMessage: "Define this model text as the main organization",
  confirmDeleteEmail: "Are you sure you want to delete this email?",
  yesDelete: "YES, DELETE",
  editMainTextConfirm: "Are you sure you want to make this main text?",
  UndoEditMainTextConfirm:
    "Are you sure you dont want to use this as a main text?",
  mainTextModel: "This is a main text model!",
  newTemplate: "Untitled template",
  "sending in progress": "bezig met verzenden",
  partner_guest: "partner guest",
  coupon_model: "coupon",
  selectCommunity: "choose your community",
  listCommunity: "List of member communities",
  CommunityUnauthorised:
    "This community does not have access to this application",
  "Drag and drop a picture here, or click to select picture to upload.": "",
  reset_generic_emails:
    "When you reset the emails, the platform reloads the most relevant email (that of your community, or failing that, that of the tamtam.pro platform) and replaces the currently active email",
  Context: "",
  reset_generic_email:
    "When you reset this email, the platform reloads the most relevant email (that of your community, or failing that, that of the tamtam.pro platform) and replaces it",
  ATTESTATION: "",
  ORDER: "",
  INVOICE: "",
  TAG: "",
  ARTICLE: "",
  CLICKED: "",
  Centre: "Center",
  deleteCampaignAutomationConfirm: "Are you sure you want to delete this template?",
  errorTheme: "The theme you selected does not have a default subject",
  eventCampaignTemplateEdit: "Event Campaign Template Edit:",
  eventCampaignTemplateCreate: "Event Campaign Template Creation:",
  automationCampaignName: "Automation campaign name:",
  campaignSubject: "Campaign Subject:",
  theme: "Theme:",
  model: "Model",
  simple: "Simple",
  contentForTheme: "Content",
  deleteTemplate: "Delete the template",
  selectTheme: "Select the theme",
  templatesOverview: "Event Campaign Templates Overview",
  noDataFound: "No data Found",
  createNewTemplate: "Create a new template",
  recipientsSelection:  "Recipients selection",
  confirmSwitchTheme:
    "You are about to switch templates. Styles and content will reset to match the new template! Are you sure you want to continue ?",
  invalidName:
    "object must be at least 2 characters and not more than 50 characters",
  mailingListAdded: "The mailing list was saved successfully",
  confirmDeleteCategory:
    "Are you sure you want to delete this theme category ?",
  mailingListEdited: "The mailing list was edited successfully",
  editSuccess: "Successful editing",
  "3 langues available": "",
  "Type the title of your category": "",
  "List of categories": "",
  "Add, modify or delete your categories": "",
  event_cycle: "event cycle",
  acceptedApproval: "Approval accepted by",
  PAYMENT_REMINDER: "PAYMENT REMINDER",
  month: new Array(
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ),
  DEFAULT_MODE: "Default mode",
  oeccbbMembers: "Membres oeccbb",
  selectType: "Please select a type",
  FEMALE: "",
  MALE: "",
  monthly: "",
  annual: "",
  fr: "French",
  en: "English",
  nl: "Dutch",
  enterFreeSection: "Configure your free sections to continue!",
  CYCLE_GUEST: "CYCLE GUEST",
  scheduled_date_expired: "not approved | scheduled date expired",
  optionalName: "optional",
  "mail express": "express mail",
  yesContinue: "YES, CONTINUE",
  confirmContinue: "Are you sure you want to continue?",
  united_associates: "united associates",
  steps_paragraph:
    "To send your newsletter campaign you must follow the following steps",
  duplicateNewsletterTitle: "Duplicate the newsletter",
  duplicateNewsletter: "Do you want to duplicate the newsletter?",
  warningEmptyNewsletter: "The items are not configured for this period",
  aproveAndSave: "Approve and save",
  Share_newsletter_campaign: "Share newsletter campaign",
  Anti_spam_test_01: "Anti spam test 01",
  Broadcast_link: "Broadcast link",
  To_copy: "To copy",
  Add_your_broadcast_contacts: "Add a contact for sharing",
  This_campaign_is_shared_with: "This campaign is shared with ",
  waiting_for_campaign_to_be_sent_to: "waiting for campaign to be sent  ",
  Contacts: "contact(s)",
  clicks_count: "Number of clicks",
  Emails_opened: "Emails opened",
  Send: "Send",
  Description_add_your_broadcast_contacts:
    "Here you add the email address of a contact for whom you want this email to be sent.",
  Description_waiting_for_campaign_to_be_sent_to:
    "This campaign is sent securely to the people listed below without using your auto-login",
  sending_steps: "Sending steps",
  newsletter_config_title: "Configure your campaign information",
  See_more: "See more",
  See_less: "See less",
  schedule_sending_campaign: "Schedule the sending of your campaign",
  result_number: "Number of results",
  manualRecipientNullWarning: "Add email manually or deselect manual type.",
  max_recipients_alert:
    "You have exceeded the recipient limit allowed for your plan",
  max_recipients_alert_desc:
    "Consider adjusting your plan to allow a higher number of recipients.",
  selectAll: "Select all",
  info_selection: "The 20 recipients of this page are selected.",
  desc_selection: "recipients in",
  desc_selection_1: "Select only the ",
  desc_selection_2: "recipients of this page",
  the: "The",
  its_selected: "are selected.",
  clear_selection: "Clear the selection.",
  deselect: "Deselect",
  personalized: "Personalised",
  lite: "Lite",
  edito: "Edito",
  basic: "Basic",
  choose_recipients_warning:
    "Please select at least one recipient for the type",
  number_of_newsletter: "Newsletter N°",
  introduction: "Introduction",
  invalid_introduction:
    "The introduction must be at least 5 characters long and no more than 200 characters long",
  placeholder_intro: "Provide an introductory text for your campaign",
  list_campaigns: "Campaigns list",
  program_newsletters: "program newsletters",
  number_of_campaigns: "number of campaigns",
  HALT: "stop",
  total: "Total",
  approval_threshold: "Approval threshold",
  principal: "Principal",
  approved: "Approved",
  rejected: "Rejected",
  sent: "Sent",
  approvalAccepted: " Approval accepted",
  approvalRejected: " Approval rejected",
  pendingApproval: "Pending approval",
  Reduce: "Reduce",
  readMore: "Read more",
  resendApproval: "Resend approval",
  cupboard: "Locker / Cupboard",
  profession: "Profession",
  auto_send_approved_newsletter_message:
    "Send the campaign once the approval threshold has been reached",
  valid_emails: "Valid emails",
  duplicate_users_alert:
    "Total number of unique recipients for selected types (duplicates have been removed).’",
  send_approval: "send approval",
  new_mail_address: "New email address",
  mail_address: "mail address...",
  mail_address_invalid: "Please enter a valid email address",
  confirm_unsubscribe_msg:
    "Please confirm that you wish to unsubscribe your email address",
  from_future_mails_from: "from future e-mails from",
  unsubscribed_msg: "You've been unsubscribed !",
  warning_unsubscribe_msg: "You will no longer receive emails from",
  unsubscribed_by_accident: ", unsubscribed by accident ?",
  subscribed_msg: "subscribe",
  unsubscribe_from_ua :"Unsubscribe from United Associate",
  subject_message_info_part_1: "Please remove ",
  subject_message_info_part_2: " characters from the text.",
  steps_paragraph_ritch_text: "To send your ritch text campaign you must follow the following steps",
  duplicateRitchTextTitle: "Duplicate the ritch text",
  duplicateRitchText: "Do you want to duplicate the ritch text?",
  list_campaigns_subtitle: "View and manage your newsletter campaigns",
  uploadImage: "Upload image",
  imageUpload: "Image uploaded and saved successfully",
  imageUploadSuccess: "Upload Successful",
  imageNoUpload: "Error during image upload",
  imageUploadError: "Upload Failed",
  secondSignatureEmail: "Second signature email",
  enterProfession :"Enter your profession",
  giveCampaignTitle: "Give your campaign a title",
  addsecondsignature: "Add a second signature",
  pasteimageurl: "Paste image URL",
  imageurl: "Image URL",
};
